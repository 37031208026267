<template>
  <div class="mx-auto my-auto text-center" v-if="isLoading && !menuLoaded">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
  <div v-if="!isLoading && !error && menuLoaded">
    <div class="card mb-7">
      <div class="card-body pt-6 pb-0">
        <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div
              class="d-flex justify-content-between align-items-start flex-wrap mb-2"
            >
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-2">
                  <a class="text-gray-800 fs-2 fw-bolder me-1 text-uppercase"
                    >{{ persona.cognome }} {{ persona.nome }}</a
                  >
                </div>
                <div class="d-flex flex-wrap fw-bold fs-6 pe-2">
                  <span class="d-flex align-items-center text-gray-400 me-5">
                    <span class="svg-icon svg-icon-4 me-1">
                      <i class="bi bi-upc" />
                    </span>
                    <span class="text-uppercase"> {{ persona.cod_fisc }} </span>
                  </span>
                  <span class="d-flex align-items-center text-gray-400 me-5">
                    <span class="svg-icon svg-icon-4 me-1">
                      <i class="bi bi-geo-alt" />
                      {{ persona.comune_residenza_label }}
                    </span>
                  </span>
                  <span class="d-flex align-items-center text-gray-400">
                    <span class="svg-icon svg-icon-4 me-1">
                      <i class="fas fa-birthday-cake me-1" />
                      {{ persona.data_nascita }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 text-end">
            <div
              @click="signOut"
              class="btn text-hover-primary text-gray-600 fs-6 text-end p-0 m-0"
            >
              <i class="bi bi-arrow-left fs-6"></i>
              Esci
            </div>
          </div>
        </div>
        <div class="d-flex overflow-auto h-55px">
          <ul
            class="nav nav-stretch text-center nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
          >
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'bacheca-insegnante',
                  params: {
                    id_persona: id_persona,
                  },
                }"
                class="nav-link text-active-primary me-1 fs-6"
                active-class="active"
              >
                Bacheca
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'anagrafica-insegnante',
                  params: {
                    id_persona: id_persona,
                  },
                }"
                class="nav-link text-active-primary me-1 fs-6"
                active-class="active"
              >
                Anagrafica
              </router-link>
            </li>
            <li class="nav-item" v-if="isEnabled('fnAccessoInsegnante')">
              <router-link
                :to="{
                  name: 'albo-insegnante',
                  params: {
                    id_persona: id_persona,
                  },
                }"
                class="nav-link text-active-primary me-1 fs-6"
                active-class="active"
              >
                Albo
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'economato-personale',
                  params: {
                    id_persona: id_persona,
                  },
                }"
                class="nav-link text-active-primary me-1 fs-6"
                active-class="active"
              >
                Economato
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'lista-corsi-iscrizioni',
                  params: {
                    id_persona: id_persona,
                  },
                }"
                class="nav-link text-active-primary me-1 fs-6"
                active-class="active"
              >
                Corsi
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'attestati-insegnante-list-insegnante',
                  params: {
                    id_persona: id_persona,
                  },
                }"
                class="nav-link text-active-primary me-1 fs-6"
                active-class="active"
              >
                Attestati
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'documenti-insegnante',
                  params: {
                    id_persona: id_persona,
                  },
                }"
                class="nav-link text-active-primary me-1 fs-6"
                active-class="active"
              >
                Documenti
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'formazione-insegnante',
                  params: {
                    id_persona: id_persona,
                  },
                }"
                class="nav-link text-active-primary me-1 fs-6"
                active-class="active"
              >
                Formazione
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'storico-insegnante',
                  params: {
                    id_persona: id_persona,
                  },
                }"
                class="nav-link text-active-primary me-1 fs-6"
                active-class="active"
              >
                Storico
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'classifica-insegnante',
                  params: {
                    id_persona: id_persona,
                  },
                }"
                class="nav-link text-active-primary me-1 fs-6"
                active-class="active"
              >
                Classifica
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'privacy-insegnante',
                  params: {
                    id_persona: id_persona,
                  },
                }"
                class="nav-link text-active-primary me-1 fs-6"
                active-class="active"
              >
                Privacy
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <router-view :persona="persona" :fromInsegnante="true"></router-view>
  </div>
  <div v-if="!isLoading && error && menuLoaded">
    <div class="text-gray-700 pt-3 fs-2 text-center">
      Attenzione! Si è verificato un errore. Riprovare più tardi.
    </div>
    <div class="text-center mt-4">
      <router-link
        v-if="pagesLength != 1 && hasAlbo"
        :to="`/albo`"
        class="btn text-hover-primary text-gray-600 fs-5 text-end p-0 m-0"
      >
        <i class="bi bi-arrow-left fs-6"></i>
        Albo
      </router-link>
      <a
        v-else
        :href="redirectInsegnanti"
        class="btn text-hover-primary text-gray-600 fs-5 text-end p-0 m-0"
      >
        <i class="bi bi-arrow-left fs-6"></i>
        Indietro
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { viewPersona } from "@/requests/alboInsegnateRequests";
import { useMsal } from "@/composition-api/useMsal";
import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";

import { useCleanStore } from "@/composables/emptyStore";
import isEnabled from "@/composables/isEnabled.js";

export default defineComponent({
  name: "accesso-insegnanti",
  components: {},
  setup() {
    const { instance } = useMsal();

    const route = useRoute();
    const id_persona = ref(route.params.id_persona);

    const isLoading = ref(false);
    const error = ref(false);

    const persona = ref({});

    const dettaglioPersona = (id) => {
      isLoading.value = true;
      error.value = false;
      viewPersona(id).then((res) => {
        isLoading.value = false;
        if (res.status == 200) {
          persona.value = res.data.results[0];
        } else {
          error.value = true;
        }
      });
    };
    dettaglioPersona(id_persona.value);

    const signOut = async () => {
      const idTokenHint = localStorage.getItem("tokenAdb2c");
      localStorage.clear();
      useCleanStore();
      await instance.logoutRedirect({
        idTokenHint: idTokenHint,
      });
    };

    const { pagesLength, redirectInsegnanti, menuLoaded, hasAlbo } =
      useRedirectInsegnanti();

    return {
      id_persona,
      persona,
      error,
      isLoading,
      signOut,
      pagesLength,
      redirectInsegnanti,
      menuLoaded,
      hasAlbo,
      isEnabled,
    };
  },
});
</script>

<style scoped>
.username:hover {
  background: #a1a5b7 !important;
}

.badge {
  height: 26px;
  margin-top: 1rem;
}
</style>
